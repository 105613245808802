//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';

// import Home from '../views/Home.vue';
import Home from '../views/Home.vue'
import KnowUs from '../views/KnowUs.vue'
import JoinUs from '../views/JoinUs.vue'
import LinkUs from '../views/LinkUs.vue'
import PostDetail from '../views/PostDetail.vue'
import Product from '../views/Product.vue'

//第三方库需要use一下才能用
Vue.use(VueRouter)

//定义routes路由的集合，数组类型
const routes = [
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {   
        path: '/', 
        name: '主页', 
        component: Home
    },
    {   
        path: '/product', 
        name: '产品中心', 
        component: Product
    },
    {   
        path: '/linkus', 
        name: '了解我们', 
        component: LinkUs
    },
    {   
        path: '/joinus', 
        name: '加入我们', 
        component: JoinUs
    },
    {   
        path: '/postdetail', 
        name: '岗位信息', 
        component: PostDetail
    },


    {   
        path: '/knowus', 
        name: '了解我们', 
        component: KnowUs
    },

]

//实例化VueRouter并将routes添加进去
const router = new VueRouter({
//ES6简写，等于routes：routes
    routes
});

//抛出这个这个实例对象方便外部读取以及访问
export default router