<template>
  <div class="link-us-container">
    <HeaderBar selected="linkus" />
    <div class="sub-banner">
        <img
            src="../assets/images/ALL IN GAME 致力于给玩家带来极致的游戏体验.png"
            alt="ALL IN GAME 致力于给玩家带来极致的游戏体验"
        />
    </div>
    <div class="main-container">
      <div class="bg-left-top">
        <img src="../assets/images/bg_left_top.png" alt="" />
      </div>
      <div class="bg-right-bottom">
        <img src="../assets/images/bg_right_bottom.png" alt="" />
      </div>

      <div class="content">
        <div class="content-item">
          <div class="content-title">
            <div class="title">联系我们</div>
            <div class="title-img">
              <img src="../assets/images/title_bottom_img.png" alt="" />
            </div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center">
            <baidu-map class="bm-view" :center="center" :zoom="zoom" ak="zCFxUutpTzfn9pRGN10eWBf3H9mhqNsc">
              <bm-marker :position="{lng: 113.5255823439, lat: 22.2595973363}" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label content="" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
              </bm-marker>
            </baidu-map>
            <div class="info-container">
              <div class="info-item company-name">{{ companyInfo.companyName }}</div>
              <div class="divider"></div>
              <div class="info-item normal">
                <img src="../assets/images/icon_address.png" /><span>地址：{{ companyInfo.companyAddr }}</span>
              </div>
              <div class="divider"></div>
              <div class="info-item normal">
                <img src="../assets/images/icon_phone.png" /><span>电话：{{ companyInfo.companyTel }}</span>
              </div>
              <div class="divider"></div>
              <div class="info-item normal">
                <img src="../assets/images/icon_fax.png" /><span>传真：{{ companyInfo.fax }}</span>
              </div>
              <div class="divider"></div>
              <div class="info-item normal">
                <img src="../assets/images/icon_email.png" /><span>E-mail：{{ companyInfo.companyMail }}</span>
              </div>
              <div class="divider"></div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Copyright from '../components/Copyright.vue'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { BmMarker } from 'vue-baidu-map'
import * as HomeApi from '../api/home'

export default {
  components: {
    HeaderBar,
    Copyright,
    BaiduMap,
    BmMarker
  },
  data () {
    return {
      BannerImgs: [
        require('../assets/images/banner1.png'),
        require('../assets/images/banner2.png'),
        require('../assets/images/banner3.png')
      ],
      center: {lng: 113.5135823439, lat: 22.2595973363},
      zoom: 14,
      companyInfo: null
    }
  },
  mounted() {
    HomeApi.getHomePage().then(res => {
      this.companyInfo = res.data[0]
      console.log(89, this.companyInfo)
    })
  }
}
</script>
<style lang="less" scoped>
.sub-banner {
    width: 100%;
    height: 350px;
    background: url('../assets/images/产品中心顶部.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-container {
  position: relative;
  width: 100%;
  background: #e9e9e9;

  .bg-left-top {
    position: absolute;
    left: 0;
    top: 0;
  }
  .bg-right-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .content {
    padding: 30px 0;

    .content-item {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .content-title {
        padding: 20px 0;
        font-size: 1.5em;
        font-weight: bolder;
        color: #cdb188;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        width: 600px;

        .info-item {
          padding: 20px 0;
          display: flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
            margin: 10px;
          }
        }

        .company-name {
          color: #cdb188;
          font-size: 1.5em;
        }

        .divider {
          width: 100%;
          height: 1px;
          background: #d1d1d1;
        }
      }
    }
    .year-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .year-item {
        display: flex;
        justify-content: center;
        width: 60%;
        height: 300px;
        margin: 20px 0;

        .year-item-left {
          position: relative;
          width: 50%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            padding: 10px 20px;
            width: 100px;
            font-size: 1.5em;
            color: #fff;
            position: absolute;
          }

          img {
            width: 100%;
            height: 300px;
          }
        }
        .year-item-right {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: -moz-linear-gradient(
            left,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -ms-linear-gradient(
            left,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -o-linear-gradient(
            left,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -webkit-linear-gradient(
            left,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );

          .title {
            color: #a16a1b;
            font-size: 1.5em;
            width: 100%;
            width: 90%;
          }
          .content {
            width: 90%;
          }
        }
        .other {
          background: -moz-linear-gradient(
            right,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -ms-linear-gradient(
            right,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -o-linear-gradient(
            right,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
          background: -webkit-linear-gradient(
            right,
            rgb(219, 205, 184, 1),
            rgb(233, 233, 233, 0)
          );
        }
      }
    }
  }
}
.bm-view {
  width: 600px;
  height: 400px;
}
</style>
