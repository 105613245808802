<template>
    <div class="home-container">
        <HeaderBar selected="home" />
        <Banner :BannerImgs="BannerImgs" />
        <div class="main-container">
            <div class="bg-left-top">
                <img src="../assets/images/bg_left_top.png" alt="">
            </div>
            <div class="bg-right-bottom">
                <img src="../assets/images/bg_right_bottom.png" alt="">
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="content-title">
                        <div class="title">了解我们</div>
                        <div class="title-img">
                            <img src="../assets/images/title_bottom_img.png" alt="">
                        </div>
                    </div>
                    <div class="content-row">
                        <div class="content-list">
                            <div class="content-column" @mouseover="mouseOver(item.auId)" @mouseleave="mouseLeave()" v-for="(item, index) in aboutUsList" :key="index">
                                <img :src="item.bgImg" alt="">
                                <div class="content-column-name">{{ item.title }}</div>
                                <div v-if="currentAuId == item.auId" class="hover-detail">
                                    <img src="../assets/images/selected_img.png" alt="">
                                    <div class="hover-content">
                                        <div class="icon">
                                            <img :src="item.icon" alt="">
                                        </div>
                                        <label>{{ item.title }}</label>
                                        <img src="../assets/images/selected-br.png" alt="">
                                        <div class="introduce">
                                            {{ item.remark }}
                                        </div>
                                        <div class="btn-detail" @click="knowusClick(item.auId)">
                                            查看详情
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-title">
                        <div class="title">加入我们</div>
                        <div class="title-img">
                            <img src="../assets/images/title_bottom_img.png" alt="">
                        </div>
                    </div>
                    <div class="content-row">
                        <div class="content-list">
                            <div class="content-column" v-for="(item, index) in jobTypeList" :key="index" @click="positionClick(item.jtId)">
                                <img class="join-us-img" :src="item.bgImg" alt="">
                                <div class="content-column-name">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Copyright />
    </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Banner from '../components/Banner.vue'
import Copyright from '../components/Copyright.vue'
import * as HomeApi from '../api/home'
import * as Aboutus from '../api/aboutUs'
import * as JobApi from '../api/job'
export default {
    components: {
        HeaderBar,
        Banner,
        Copyright
    },
    methods: {
        mouseOver(id) {
            this.currentAuId = id
        },
        mouseLeave() {
            this.currentAuId = ''
        },
        getJobType() {
            JobApi.getJobType().then(res => {
                this.jobTypeList = res.data
            })
        },
        getAboutus() {
            Aboutus.getAboutUs().then(res => {
                this.aboutUsList = res.data

            })
        },
        positionClick(id) {
            this.$router.push('/joinus?id=' + id)
        },
        knowusClick(id) {
            console.log(id)
            this.$router.push('/knowus?id=' + id)
        }
    },
    mounted() {
        HomeApi.getHomePage().then(res => {
            let data = res.data[0]
            this.BannerImgs.push(data.swiperImage1)
            this.BannerImgs.push(data.swiperImage2)
            this.BannerImgs.push(data.swiperImage3)
        })

        this.getAboutus()
        this.getJobType()
    },
    data() {
        return {
            BannerImgs: [],
            aboutUsList: [],
            currentAuId: null,
            jobTypeList: []
        }
    }
}
</script>
<style lang="less" scoped>
    .main-container {
        position: relative;
        width: 100%;
        background: #E9E9E9;

        .bg-left-top {
            position: absolute;
            left: 0;
            top: 0;
        }
        .bg-right-bottom {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .content {
            padding: 30px 0;
            .content-item {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .content-title {
                    padding: 20px 0;
                    font-size: 1.5em;
                    font-weight: bolder;
                    color: #CDB188;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            .content-list {
                display: flex;
                justify-content: center;
                align-items: center;

                .content-column {
                    margin: 0 10px;
                    position: relative;
                    cursor: pointer;
                    transition: all 0.6s;
                    
                    img {
                        width: 260px;
                        height: 260px;
                    }
                    
                    .hover-detail {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        background-color: rgba(0, 0, 0, 0.5);

                        img {
                            height: 100%;
                            width: 100%;
                        }
                        .hover-content {
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            height: 270px;
                            width: 270px;
                            

                            .icon {
                                width: 100%;
                                text-align: center;
                                img {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                            label {
                                color: #CDB188;
                            }
                            img {
                                width: auto;
                                height: auto;
                            }
                            .introduce {
                                padding: 20px;
                                color: #FFF;
                                text-align: center;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                white-space:nowrap;
                                width: 80%;
                            }
                            .btn-detail {
                                width: 80%;
                                height: 40px;
                                line-height: 40px;
                                text-align: center;
                                border-radius: 20px;
                                background: #FACE89;
                                cursor: pointer;
                            }
                        }
                    }
                    .hover-detail:hover {
                        background-color: rgba(0, 0, 0, 0.5);
                        
                    }

                    .content-column-name {
                        position: absolute;
                        bottom: 0;
                        height: 50px;
                        line-height: 50px;
                        background: rgba(0, 0, 0, 0.5);
                        width: 100%;
                        color: #FFF;
                        text-align: center;
                    }
                }
                .content-column:hover {
                    background-color: rgba(0, 0, 0, 0.5);
                    transform: scale(1.1);
                }
            }
        }
    }
</style>