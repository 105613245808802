import request from '@/utils/request'

export function getJobType(query) {
  return request({
    url: '/home/api/jobTypePage',
    method: 'POST',
    params:query
  })
}

export function getJobs(query) {
  return request({
    url: '/home/api/jobsPage',
    method: 'POST',
    params:query
  })
}

export function getJobDetail(query) {
  return request({
    url: '/home/api/jobsPageDetail',
    method: 'POST',
    params:query
  })
}