<template>
  <div class="product-container">
    <HeaderBar selected="product" />
    <div class="sub-banner">
      <img
        src="../assets/images/ALL IN GAME 致力于给玩家带来极致的游戏体验.png"
        alt="ALL IN GAME 致力于给玩家带来极致的游戏体验"
      />
    </div>
    <div class="main-container">
      <div class="bg-left-top">
        <img src="../assets/images/bg_left_top.png" alt="" />
      </div>
      <div class="bg-right-bottom">
        <img src="../assets/images/bg_right_bottom.png" alt="" />
      </div>
      <div></div>
      <div class="content">
        <div class="content-title">
          <div class="title">{{ currentProduct.name }}</div>
          <div class="title-img">
            <img src="../assets/images/title_bottom_img.png" alt="" />
          </div>
        </div>
        <div class="product-list">
          <div style="display: flex; justify-content: content; align-items: center">
            <img style="margin-left: 100px; cursor: pointer; z-index: 99" src="../assets/images/left.png" @click="getCurrentProduct('last')" alt="">
            <div class="carousel-content">
              <el-carousel :interval="5000" arrow="always">
                <el-carousel-item>
                  <div class="product-carousel">
                    <div class="logo">
                      <img @click="logoClick(currentProduct.link)" style="cursor: pointer" :src="currentProduct.icon" alt="产品logo" />
                    </div>
                    <div class="text">
                      <div v-html="currentProduct.content"></div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="number-content">
                <div class="item">
                  <div class="number">{{ currentProduct.playerAmount ? currentProduct.playerAmount : 0 }}</div>
                  <div class="text">发行年份</div>
                </div>
                <div class="item">
                  <div class="number">{{ currentProduct.onlineAmount ? currentProduct.onlineAmount : 0 }}</div>
                  <div class="text">玩家人数</div>
                </div>
                <div class="item">
                  <div class="number">{{ currentProduct.topAmount ? currentProduct.topAmount : 0 }}</div>
                  <div class="text">总计流水</div>
                </div>
                <div class="item">
                  <div class="number">{{ currentProduct.sumAmount ? currentProduct.sumAmount : 0 }}</div>
                  <div class="text">上线国家</div>
                </div>
              </div>
            </div>
            <img style="margin-right: 100px; cursor: pointer; z-index: 99" src="../assets/images/right.png" @click="getCurrentProduct('next')" alt="">
          </div>
          <div class="game-card">
            <el-carousel :interval="4000" type="card" height="310px">
              <el-carousel-item>
                <div class="game-card-item">
                  <img :src="currentProduct.image1" alt="..." />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="game-card-item">
                  <img :src="currentProduct.image2" alt="..." />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="game-card-item">
                  <img :src="currentProduct.image3" alt="..." />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="video-content">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              name="videoPlayer"
              id="video"
              :playsinline="true"
              :options="currentProduct.playerOption"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
              @waiting="onPlayerWaiting($event)"
              @timeupdate="onPlayerTimeupdate($event)"
              @statechanged="playerStateChanged($event)"
              @canplaythrough="onPlayerCanplaythrough($event)"
              @ready="playerReadied"
            ></video-player>
          </div>
        </div>
        
      </div>
    </div>
    <Copyright />
  </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Copyright from '../components/Copyright.vue'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import * as Product from '../api/product'
export default {
  components: {
    HeaderBar,
    Copyright,
    videoPlayer
  },
  data () {
    return {
      playTime: '',
      current: '',
      productList: [],
      currentIndex: 0,
      currentProduct: {}
    }
  },

  methods: {
    welfareHover (param) {
      this.hoverItem = param
    },
    mouseleave () {
      this.hoverItem = ''
    },
    // 播放回调
    onPlayerPlay (player) {
      console.log('player play!', player)
    },

    // 暂停回调
    onPlayerPause (player) {
      console.log('player pause!', player)
    },

    // 视频播完回调
    onPlayerEnded () {
      // console.log(player)
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting (player) {
      let time = localStorage.getItem('cacheTime')
      if (player.cache_.currentTime - Number(time) > 0.1) {
        this.current = Number(time)
        this.playerReadied(player)
      } else {
        this.current = player.cache_.currentTime
      }
    },

    // 已开始播放回调
    onPlayerPlaying () {
      // console.log(player)
    },

    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata () {
      // console.log(player)
    },

    // // 当前播放位置发生变化时触发。
    onPlayerTimeupdate (player) {
      this.playTime = player.cache_.currentTime
      let playTime = player.cache_.currentTime
      setTimeout(function () {
        localStorage.setItem('cacheTime', playTime)
      }, 500)

      let time = localStorage.getItem('cacheTime')
      if (player.cache_.currentTime - Number(time) > 2) {
        this.current = Number(time)
        this.playerReadied(player)
      } else {
        this.current = player.cache_.currentTime
      }
    },

    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay (player) {
      console.log('player Canplay!', player)
    },

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough (player) {
      console.log('player Canplaythrough!', player)
    },

    //播放状态改变回调
    playerStateChanged (playerCurrentState) {
      console.log('player current update state', playerCurrentState)
    },

    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied (player) {
      console.log('example player 1 readied', player)
      player.currentTime(this.current)
    },

    getProducts() {
      Product.getProducts().then(res => {
        console.log(res)
        this.productList = res.data
        this.currentProduct = res.data[0]
        for(let i = 0; i < this.productList.length; i++) {
          let item = this.productList[i]
          console.log(216, item.mediaUrl)
          this.productList[i].playerOption = {
            playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
            autoplay: false, // 如果为true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 是否视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                type: 'video/mp4', // 类型
                src: item.mediaUrl // url地址
              }
            ],
            poster: '', // 封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true, // 当前时间和持续时间的分隔符
              durationDisplay: true, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          }
        }
      })
    },
    getCurrentProduct(option) {
      console.log(option)
      if (option == 'last') {
        if (this.currentIndex - 1 < 0) {
          this.currentIndex = this.productList.length - 1
        } else {
          this.currentIndex -= 1
        }
      }
      if (option == 'next') {
        if (this.currentIndex + 1 > this.productList.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex += 1
        }
      }
      this.currentProduct = this.productList[this.currentIndex]
    },
    logoClick(url) {
      window.open(url)
    }
  },
  mounted() {
    this.getProducts()
  }
}
</script>
<style lang="less" scoped>
.product-container {
  .sub-banner {
    width: 100%;
    height: 350px;
    background: url('../assets/images/产品中心顶部.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-container {
    position: relative;
    width: 100%;
    background: #e9e9e9;

    .bg-left-top {
      position: absolute;
      left: 0;
      top: 0;
    }
    .bg-right-bottom {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .content {
      padding: 30px 0;
      .content-title {
        padding: 20px 0;
        font-size: 1.5em;
        font-weight: bolder;
        color: #cdb188;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .product-list {
        .carousel-content {
          width: 80%;
          margin: 0 auto;
          .product-carousel {
            padding: 10px 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              font-size: 18px;
              color: #666666;

              div {
                padding: 5px 0;
              }
              p {
                text-indent: 25px;
              }
            }
            .logo {
              margin: 0 50px;
              img {
                max-width: 200px;
              }
            }
          }
          .number-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            padding: 10px 200px;
            .item {
              flex: 1;
              text-align: center;
              .number {
                color: #002f9b;
                font-size: 60px;
                font-family: 'califb';
              }
              .text {
                font-size: 16px;
              }
            }
          }
          /deep/.el-carousel__arrow--left {
            // width: 114px;
            // height: 164px;
            // background: url('../assets/images/left.png') no-repeat;
            background-color: transparent;
            .el-icon-arrow-left {
              display: none;
            }
          }
          /deep/.el-carousel__arrow--right {
            // width: 114px;
            // height: 164px;
            // background: url('../assets/images/right.png') no-repeat;
            background-color: transparent;
            .el-icon-arrow-right {
              display: none;
            }
          }
          /deep/.el-carousel__indicator {
            display: none;
          }
        }

        .game-card {
          margin: 100px auto;
          width: 1344px;
          .game-card-item {
            img {
              width: 100%;
            }
          }
          /deep/.el-carousel__arrow--left {
            display: none;
          }
          /deep/.el-carousel__arrow--right {
            display: none;
          }
          /deep/.el-carousel__indicator .el-carousel__button {
            width: 10px;
            height: 10px;
            padding: 0;
            border: 1px solid #cdb188;
            margin: 4px 0;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
          }
          /deep/.el-carousel__indicator.is-active .el-carousel__button {
            background-color: #cdb188;
          }
        }
        .video-content {
          width: 70%;
          margin: 100px auto;
          -webkit-box-shadow: 0px 10px 10px #333;
          -moz-box-shadow: 0px 10px 10px #333;
          box-shadow: 0px 15px 10px #333;
          /deep/.vjs-big-play-button {
            border: none;
            width: 150px !important;
            height: 150px !important;
            background-color: transparent;
            background: url('../assets/images/play.png') no-repeat;
            background-size: 100% 100%;
            .vjs-icon-placeholder {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
