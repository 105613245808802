<template>
    <div class="banner-container">
        <el-carousel :interval="5000" arrow="always" :height="bannerHeight+'px'">
            <el-carousel-item v-for="(item, index) in BannerImgs" :key="index">
                <el-row :gutter="12">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height:340px;margin-bottom: 20px;">
                        <img ref="bannerHeight" :src="item" alt="" @load="imgLoad" style="width: 100%">
                    </el-col>
                </el-row>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name: 'Banner',
    props: {
        BannerImgs: {
            type: Array,
            default: () => []
        }
    },
    data() {
      return {
        bannerHeight:""
      };
    },
    mounted(){
        this.imgLoad();
        window.addEventListener('resize',() => {
            this.bannerHeight=this.$refs.bannerHeight[0].height;
            this.imgLoad();
        },false)
    },
    methods: {
        imgLoad(){
            this.$nextTick(()=>{
                this.bannerHeight=this.$refs.bannerHeight[0].height;
                console.log(this.$refs.bannerHeight[0].height);
                    // document.getElementsByClassName拿到的是数组并非某一个对象
                //var testH=document.getElementById("test-div");
                //testH.style.height= this.bannerHeight+"px";
            })
        }
    }
}
</script>
<style lang="less" scoped>

</style>