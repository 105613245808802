<template>
    <div class="copyright-container">
        <div class="row">
            <div class="weixin-code">
                <div>官方微信号</div>
                <img :src="info.companyCode" alt="">
                <div>扫码关注</div>
            </div>
            <div class="divider-line"></div>
            <!-- <div class="info">
                <div style="color: #CDB188; font-weight: bolder">有限公司</div>
                <div>Unit 2119, Level 21,Landmark North, 39 Lung Sum Avenue, Sheung Shui, N.T.</div>
                <div>地址：</div>
                <div>电话：82673 3268</div>
                <div>传真：2673 3208</div>
            </div> -->
            <div class="info">
                <div style="color: #CDB188; font-weight: bolder">{{ info.companyName }}</div>
                <div>{{ info.companyNameEn }}</div>
                <div>地址：{{ info.companyAddr }}</div>
                <div>电话：{{ info.companyTel }}</div>
                <div>邮箱：{{ info.companyMail }}</div>
            </div>
        </div>
        <a class="row" href=" http://beian.miit.gov.cn">Copyright@2010-2021文景科技有限公司 | 粤ICP备16032990号-1</a>
    </div>
</template>
<script>
import * as homeApi from '../api/home'
export default {
    name: 'Copyright',
    data() {
        return {
            info: null
        }
    },
    mounted() {
        homeApi.getHomePage().then(res => {
            console.log(27, res)
            this.info = res.data[0]
        })
    }
}
</script>
<style lang="less" scoped>
    a {
        text-decoration:none
    }
    .copyright-container {
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #000;
        
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #464646;

            .weixin-code {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #CDB188;

                div {
                    margin: 5px;
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }
            .divider-line {
                height: 60%;
                margin: 0 50px;
                width: 1px;
                background: #464646;
            }
            .info {
                color: #464646;
                div {
                    margin: 2px;
                }
            }
        }
    }
</style>