<template>
    <div class="header-bra-container">
        <div class="menu-container">
            <div class="logo">
                <img src="../assets/images/LOGO.png" alt="">
            </div>
            <router-link to="/">
                <div :class="selected == 'home' ? 'menu-item selected' : 'menu-item'">
                    <div class="menu-name">首页</div>
                </div>
            </router-link>
            <router-link to="/knowus">
                <div :class="selected == 'knowus' ? 'menu-item selected' : 'menu-item'">
                    <div class="menu-name">了解我们</div>
                </div>
            </router-link>
            <router-link to="/product">
                <div :class="selected == 'product' ? 'menu-item selected' : 'menu-item'">
                    <div class="menu-name">产品中心</div>
                </div>
            </router-link>
            <router-link to="/joinus">
                <div :class="selected == 'joinus' ? 'menu-item selected' : 'menu-item'">
                    <div class="menu-name">加入我们</div>
                </div>
            </router-link>
            <router-link to="/linkus">
                <div :class="selected == 'linkus' ? 'menu-item selected' : 'menu-item'">
                    <div class="menu-name">联系我们</div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HeaderBar',
    props: {
        selected: {
            type: String,
            default: "home"
        }
    }
}
</script>
<style lang="less" scoped>
    a {
        text-decoration:none
    }
    .menu-container {
        height: 60px;
        width: 100%;
        position: fixed;
        z-index: 3;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            position: absolute; top: 0; left: 400px; top: 10px;

            img {
                height: 40px
            }
        }

        .menu-item {
            height: 60px;
            color: #FFFFFF;
            padding: 0 20px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .menu-selected-line {
                width: 40px;
                height: 4px;
                border-radius: 2px;
                background: #CDB188;
                margin-top: 5px;
            }
        }
        .menu-item.selected {
            padding-top: 4px;
            border-bottom: 4px solid;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            color: #CDB188;
        }
        .menu-item:active {
            color: #CDB188;
            padding: 10px 20px;
        }
    }
</style>