<template>
    <div class="know-us-container">
        <HeaderBar selected="knowus" />
        <div class="sub-banner">
            <img
                src="../assets/images/ALL IN GAME 致力于给玩家带来极致的游戏体验.png"
                alt="ALL IN GAME 致力于给玩家带来极致的游戏体验"
            />
        </div>
        <div class="main-container">
            <div class="bg-left-top">
                <img src="../assets/images/bg_left_top.png" alt="">
            </div>
            <div class="bg-right-bottom">
                <img src="../assets/images/bg_right_bottom.png" alt="">
            </div>

            <div class="content">
                <div class="menu-container">
                    <div class="menu-item" @click="menuSelectedEvent(item.auId, index)" v-for="(item, index) in aboutUsList" :key="index">
                        <img v-if="currentMenu == item.auId" src="../assets/images/knowue_selected.png" />
                        <div :style="{ color: currentMenu == item.auId ? '#FFF' : '' }">{{ item.title }}</div>
                    </div>
                </div>
                <div class="content-item">
                    <!-- <div class="content-title">
                        <div class="title">了解我们</div>
                        <div class="title-img">
                            <img src="../assets/images/title_bottom_img.png" alt="">
                        </div>
                    </div> -->
                    <div class="introduce" v-html="remark"></div>
                    
                </div>
                <div class="year-list" v-for="(item, index) in contents" :key="index">
                    <div class="year-item" v-if="index % 2 == 0">
                        <div class="year-item-left">
                            <span v-if="item.year">{{ item.year }}</span>
                            <img :src="item.image" alt="">
                        </div>
                        <div class="year-item-right">
                            <div class="title">{{ item.title }}</div>
                            <div class="date">{{ item.showTime }}</div>
                            <div class="content" v-html="item.content"></div>
                        </div>
                    </div>

                    <div class="year-item" v-if="index % 2 == 1">
                        <div class="year-item-right other">
                            <div class="title">{{ item.title }}</div>
                            <div class="date">{{ item.showTime }}</div>
                            <div class="content" v-html="item.content"></div>
                        </div>
                        <div class="year-item-left">
                            <span v-if="item.year">{{ item.year }}</span>
                            <img :src="item.image" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Copyright />
    </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Copyright from '../components/Copyright.vue'
import * as Aboutus from '../api/aboutUs'
export default {
    components: {
        HeaderBar,
        Copyright
    },
    data() {
        return {
            BannerImgs: [
                require("../assets/images/banner1.png"),
                require("../assets/images/banner2.png"),
                require("../assets/images/banner3.png")
            ],
            menuSelected: require('../assets/images/knowue_selected.png'),
            currentMenu: 'company',
            aboutUsList: [],
            remark: ''
        }
    },
    methods: {
        menuSelectedEvent(id, index) {
            console.log(index)
            console.log(this.aboutUsList[index])
            this.currentMenu = id
            this.remark = this.aboutUsList[index].remark
            this.contents = this.aboutUsList[index].contents
            console.log(this.contents)
        },

        getAboutus() {
            Aboutus.getAboutUs().then(res => {
                console.log(107, res)
                this.aboutUsList = res.data
                let id = this.$route.query.id ? this.$route.query.id :this.aboutUsList[0].auId
                this.currentMenu = id
                console.log(id)
                for (let i = 0; i < this.aboutUsList.length; i++) {
                    if (id == this.aboutUsList[i].auId) {
                        this.contents = this.aboutUsList[i].contents
                        this.remark = this.aboutUsList[i].remark
                        break
                    }
                }
            })
        }
    },
    mounted() {
        this.getAboutus()
        
    }
}
</script>
<style lang="less" scoped>
    .sub-banner {
        width: 100%;
        height: 350px;
        background: url('../assets/images/产品中心顶部.png') no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main-container {
        position: relative;
        width: 100%;
        background: #E9E9E9;

        .bg-left-top {
            position: absolute;
            left: 0;
            top: 0;
        }
        .bg-right-bottom {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .content {
            
            .menu-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .menu-item {
                    cursor: pointer;
                    position: relative;
                    width: 130px;
                    height: 62px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div {
                        position: absolute;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            
            .content-item {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .content-title {
                    padding: 20px 0;
                    font-size: 1.5em;
                    font-weight: bolder;
                    color: #CDB188;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                
                .introduce {
                    width: 40%;
                    margin-bottom: 20px;
                    text-align: center;
                    color: #AAAAAA;
                }
            }
            .year-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .year-item {
                    display: flex;
                    justify-content: center;
                    width: 50%;
                    height: 300px;
                    margin: 20px 0;

                    .year-item-left {
                        position: relative;
                        width: 50%;
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span {
                            background-image: linear-gradient(
                            to right,
                            rgba(161,106,27,0.0) 0%,
                            rgba(161,106,27,0.2) 10%,
                            rgba(161,106,27,0.5) 20%,
                            rgba(161,106,27,0.6) 40%,
                            rgba(161,106,27,0.6) 60%,
                            rgba(161,106,27,0.5) 80%,
                            rgba(161,106,27,0.2) 90%,
                            rgba(161,106,27,0.0) 100%
                            );
                            // background: rgba(0, 0, 0, 0.5);
                            text-align: center;
                            padding: 10px 20px;
                            width: 150px;
                            font-size: 1.5em;
                            color: #FFF;
                            position: absolute;
                        }

                        img {
                            width: 100%;
                            height: 300px;
                        }
                    }
                    .year-item-right {
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background:-moz-linear-gradient(left,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-ms-linear-gradient(left,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-o-linear-gradient(left,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-webkit-linear-gradient(left,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        
                        .title {
                            color: #A16A1B;
                            font-size: 1.5em;
                            width: 90%;
                        }
                        .date {
                            color: #AAAAAA;
                            width: 90%;
                            margin: 5px 0;
                        }
                        .content {
                            width: 90%;
                        }
                    }
                    .other {
                        background:-moz-linear-gradient(right,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-ms-linear-gradient(right,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-o-linear-gradient(right,rgb(219,205,184,1.0),rgb(233,233,233,0));
                        background:-webkit-linear-gradient(right,rgb(219,205,184,1.0),rgb(233,233,233,0));
                    }
                }
            }
        }
    }
</style>