<template>
  <div class="join-us-container">
    <HeaderBar selected="joinus" />
    <div class="sub-banner">
      <img
          src="../assets/images/ALL IN GAME 致力于给玩家带来极致的游戏体验.png"
          alt="ALL IN GAME 致力于给玩家带来极致的游戏体验"
      />
    </div>
    <div class="main-container">
      <div class="breadcrumb">
        <div class="breadcrumb-content">
          <span><i class="el-icon-s-home"></i>当前位置:</span>
          <span>首页</span>
          <i class="el-icon-arrow-right"></i>
          <span>
            加入我们
          </span>
          <i class="el-icon-arrow-right"></i>
          <span class="breadcrumb-active">
            岗位信息
          </span>
        </div>
      </div>
      <div class="content">
        <div class="post">
          <p>{{ jobDetail.pubTime }}</p>
          <p class="post-title">{{ jobDetail.name }}</p>
          <p>需求人数：{{ jobDetail.amount }}</p>
          <p>工作地点：{{ jobDetail.address }}</p>
        </div>
        <div class="line-tip">
          <span class="line-span">职位描述</span>
          <div class="line"></div>
        </div>
        <div class="post-detail" v-html="jobDetail.remark">
          <!--<div class="duty">
            <p>工作职责</p>
            <p>
              1.根据业务需求,完成数据平台的前端开发工作
            </p>
            <p>
              2.协同后端,共同设计并实现前后端通信接口
            </p>
            <p>
              3.参与数据平台产品需求评审
            </p>
          </div>
          <div class="require">
            <p>任职要求</p>
            <p>1.两年以上项目开发经验,计算机或相关专业本科及以上学历</p>
            <p>
              2.掌握至少-种前端框架,如React.Angular. Vue.熟悉ECMAScript6/7语法
            </p>
            <p>3.精通HTML5、CSS3.JavaScript. AJAX等Web开发技术</p>
            <p>
              4.了解至少-—门后端开发语言,如Node.,js.
              PHP等。掌握数据库和缓存系统,如MySQL , memcached , Redis等
            </p>
            <p>5.有一定的架构设计理念,熟悉常用设计模式</p>
            <p>
              6.具有较强的逻辑分析和解决问题能力,有复杂交互系统开发经验者优先
            </p>
            <p>7.需要较好沟通能力,抗压能力和较强的责任感</p>
          </div>-->
        </div>
        <div class="line-tip">
          <span class="line-span">联系我们</span>
          <div class="line"></div>
        </div>
        <div class="contact">
          <div class="contact-logo">
            <img src="../assets/images/LOGO.png" alt="LOGO" />
          </div>
          <div class="contact-text ">
            <p>联系地址：{{ homeInfo.companyAddr }}</p>
            <p>联系电话: {{ homeInfo.companyTel }}</p>
            <p>Email: {{ homeInfo.companyMail }}</p>
          </div>
        </div>
        <div>
          <el-button
            class="contact-btn"
            v-clipboard:copy="homeInfo.companyMail"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            投递简历到邮箱
          </el-button>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Copyright from '../components/Copyright.vue'
import * as JobApi from '../api/job'
import * as HomeApi from '../api/home'
export default {
  components: {
    HeaderBar,
    Copyright
  },
  data () {
    return {
      jobDetail: null
    }
  },
  methods: {
    // 复制成功
    onCopy () {
      this.$message({
        showClose: true,
        message: '已成功复制邮箱！',
        type: 'success'
      })
    },
    // 复制失败
    onError () {
      this.$message({
        showClose: true,
        message: '复制邮箱失败！',
        type: 'error'
      })
    },
    getJobDetail(id) {
      JobApi.getJobDetail({ id: id }).then(res => {
        this.jobDetail = res.data
      })
    }
  },
  mounted() {
    let id = this.$route.query.id
    HomeApi.getHomePage().then(res => {
      this.homeInfo = res.data[0]
      console.log(141, this.homeInfo)
      this.getJobDetail(id)
    })
  }
}
</script>
<style lang="less" scoped>
.join-us-container {
  .sub-banner {
    width: 100%;
    height: 350px;
    background: url('../assets/images/产品中心顶部.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-container {
    position: relative;
    width: 100%;
    background: #e9e9e9;
    .breadcrumb {
      padding: 20px 0;
      border-bottom: 1px solid #cdb188;
      color: #aeaeae;
      span {
        margin: 0 5px;
      }
      .breadcrumb-content {
        width: 70%;
        margin: 0 auto;
        text-align: right;
      }
      .breadcrumb-active {
        color: #000;
      }
    }
    .content {
      padding: 30px 0;
      width: 70%;
      margin: 0 auto;
      .line-tip {
        padding: 12px 0;
        position: relative;
        .line-span {
          background-color: #e9e9e9;
          padding-right: 10px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .line {
          width: 100%;
          height: 1px;
          background-color: #d1d1d1;
        }
      }
      .post {
        padding: 30px 0;
        color: #bbbbbb;
        p {
          margin: 6px 0;
        }
        .post-title {
          font-size: 26px;
          color: #000;
          margin: 10px 0;
        }
      }
      .post-detail {
        padding: 30px 0;
        p {
          margin: 4px 0;
        }
        .duty {
          margin-bottom: 50px;
        }
        .require {
          margin-bottom: 15px;
        }
      }
      .contact {
        display: flex;
        align-items: center;
        padding: 30px 0;
        p {
          margin: 10px 0;
        }
        .contact-logo {
          border-right: 1px solid #bbbbbb;
          padding-right: 20px;
        }
        .contact-text {
          padding-left: 20px;
        }
      }
      .contact-btn {
        background-color: #cdb188;
        color: #fff;
        padding: 20px 100px;
      }
    }
  }
}
</style>
