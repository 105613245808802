<template>
    <div class="join-us-container">
        <HeaderBar selected="joinus" />
        <div class="sub-banner">
            <img
                src="../assets/images/ALL IN GAME 致力于给玩家带来极致的游戏体验.png"
                alt="ALL IN GAME 致力于给玩家带来极致的游戏体验"
            />
        </div>
        <div class="main-container">
            <div class="bg-left-top">
                <img src="../assets/images/bg_left_top.png" alt="">
            </div>
            <div class="bg-right-bottom">
                <img src="../assets/images/bg_right_bottom.png" alt="">
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="content-title">
                        <div class="title">加入我们</div>
                        <div class="title-img">
                            <img src="../assets/images/title_bottom_img.png" alt="">
                        </div>
                    </div>
                    <div class="introduce">珠海文景科技有限公司创办于2015年，坐落于环境优美有海滨城市之称的珠海市。公司致力于游戏的研发，已有成功的游戏作品，拥有丰富的游戏开发经验。为员工提供有竞争力的薪酬待遇，完善的福利，优美的办公环境，是文景的使命之一，欢迎加入我们。</div>

                    <div class="welfare-list">
                        <div class="row">
                            <div class="column" @mouseover="welfareHover('row1_1')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb1.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row1_1'"><label>六险一金</label></div>
                                <div v-if="hoverItem == 'row1_1'" class="hover-detail">
                                    <div class="hover-detail-title">六险一金</div>
                                    <div class="hover-detail-content">公司为员工提供更加完善的保证</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row1_2')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb2.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row1_2'"><label>免费午餐</label></div>
                                <div v-if="hoverItem == 'row1_2'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row1_2'" class="hover-detail">
                                    <div class="hover-detail-title">免费午餐</div>
                                    <div class="hover-detail-content">公司为员工提供免费的美味午餐</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row1_3')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb3.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row1_3'"><label>各种团建</label></div>
                                <div v-if="hoverItem == 'row1_3'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row1_3'" class="hover-detail">
                                    <div class="hover-detail-title">各种团建</div>
                                    <div class="hover-detail-content">除了部门团建，还有公司的不定时团建活动</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row1_4')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb4.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row1_4'"><label>各种比赛奖励</label></div>
                                <div v-if="hoverItem == 'row1_4'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row1_4'" class="hover-detail">
                                    <div class="hover-detail-title">各种比赛奖励</div>
                                    <div class="hover-detail-content">篮球赛，减肥大赛，创意大赛，是你展示自己的绝佳舞台。</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column" @mouseover="welfareHover('row2_1')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb5.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row2_1'"><label>不怕你胖</label></div>
                                <div v-if="hoverItem == 'row2_1'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row2_1'" class="hover-detail">
                                    <div class="hover-detail-title">不怕你胖</div>
                                    <div class="hover-detail-content">不仅有免费的午餐，我们还有健身房，不怕你胖</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row2_2')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb6.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row2_2'"><label>各种补贴</label></div>
                                <div v-if="hoverItem == 'row2_2'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row2_2'" class="hover-detail">
                                    <div class="hover-detail-title">各种补贴</div>
                                    <div class="hover-detail-content">加班补贴，全勤补贴，交通补贴，应有尽有。</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row2_3')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb7.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row2_3'"><label>每月生日会</label></div>
                                <div v-if="hoverItem == 'row2_3'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row2_3'" class="hover-detail">
                                    <div class="hover-detail-title">每月生日会</div>
                                    <div class="hover-detail-content">每个月当月生日的员工有礼物，有蛋糕，有温度。</div>
                                </div>
                            </div>
                            <div class="column" @mouseover="welfareHover('row2_4')" @mouseleave="mouseleave">
                                <img src="../assets/images/thumb8.png" alt="">
                                <div class="welfare-name" v-if="hoverItem != 'row2_4'"><label>旅游</label></div>
                                <div v-if="hoverItem == 'row2_4'" class="hover-detail"></div>
                                <div v-if="hoverItem == 'row2_4'" class="hover-detail">
                                    <div class="hover-detail-title">旅游</div>
                                    <div class="hover-detail-content">公司基本一年会安排一次旅游。</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="position-container">
                        <div class="menu-container">
                            <div class="menu-item" v-for="(item, index) in jobType" :key="index" @click="menuSelectedEvent(item.jtId)">
                                <img v-if="currentMenu == item.jtId" src="../assets/images/knowue_selected.png" />
                                <div :style="{ color: currentMenu == item.jtId ? '#FFF' : '' }">{{item.name}}</div>
                            </div>
                        </div>
                        <div class="position-list">
                            <div v-for="(item, index) in jobList" :key="index" :class="item.jobId == positionHoverId ? 'position-item active' : 'position-item'" @mouseover="positionMouseOver(item.jobId)" @mouseleave="positionMouseLeave" @click="positionClick(item.jobId)" style="cursor: pointer">
                                <div class="left">
                                    <span style="font-size: 1.3em; font-weight: bolder">{{item.name}}</span>
                                    <span>需求人员：{{item.amount}}人</span>
                                    <span>工作地址：{{item.address}}</span>
                                </div>
                                <div class="right">
                                    发布时间：{{item.createTime}}
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        <Copyright />
    </div>
</template>
<script>
import HeaderBar from '../components/HeaderBar.vue'
import Copyright from '../components/Copyright.vue'
import * as JobApi from '../api/job'
export default {
    components: {
        HeaderBar,
        Copyright
    },
    data() {
        return {
            BannerImgs: [
                require("../assets/images/banner1.png"),
                require("../assets/images/banner2.png"),
                require("../assets/images/banner3.png")
            ],
            hoverItem: '',
            menuSelected: require('../assets/images/knowue_selected.png'),
            currentMenu: null,
            jobType: null,
            jobList: [],
            positionHoverId: ''
        }
    },
    methods: {
        welfareHover(param) {
            this.hoverItem = param
        },
        mouseleave() {
            this.hoverItem = ''
        },
        menuSelectedEvent(id) {
            this.currentMenu = id
            this.getJobs({ type: id })
        },
        getJobType() {
            JobApi.getJobType().then(res => {
                this.jobType = res.data
                let id = this.$route.query.id ? this.$route.query.id : res.data[0].jtId
                this.currentMenu = id
                this.getJobs({ type: id })
            })
        },
        getJobs(type) {
            JobApi.getJobs(type).then(res => {
                this.jobList = res.data
            })
        },
        positionMouseOver(id) {
            this.positionHoverId = id
        },
        positionMouseLeave() {
            this.positionHoverId = ''
        },
        positionClick(id) {
            this.$router.push('/postdetail?id=' + id)
        }
    },
    mounted() {
        this.getJobType()
    }
}
</script>
<style lang="less" scoped>
    .join-us-container {
        .sub-banner {
            width: 100%;
            height: 350px;
            background: url('../assets/images/产品中心顶部.png') no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .main-container {
            position: relative;
            width: 100%;
            background: #E9E9E9;

            .bg-left-top {
                position: absolute;
                left: 0;
                top: 0;
            }
            .bg-right-bottom {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .content {
                display: flex;
                flex-direction: column;
                padding: 30px 0;

                .menu-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    background: #CBC9C9;

                    .menu-item {
                        cursor: pointer;
                        position: relative;
                        width: 25%;
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div {
                            position: absolute;
                        }
                        img {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .content-item {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .content-title {
                        padding: 20px 0;
                        font-size: 1.5em;
                        font-weight: bolder;
                        color: #CDB188;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .introduce {
                        width: 40%;
                        text-align: center;
                    }

                    .welfare-list {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        margin-top: 60px;
                        .row {
                            width: 60%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .column {
                                position: relative;
                                width: 25%;
                                    height: 200px;
                                img {
                                    position: absolute;
                                    width: 100%;
                                    height: 200px;
                                }
                                .welfare-name {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    background: rgba(0, 0, 0, 0.5);
                                    height: 30px;
                                    line-height: 30px;
                                    
                                    label {
                                        margin-left: 10px;
                                        color: #FFF;
                                    }
                                }
                                .hover-detail {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    background: rgba(0, 0, 0, 0.5);
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    
                                    .hover-detail-title {
                                        font-size: 1.2em;
                                        color: #CDB188;
                                        text-align: center;
                                    }
                                    .hover-detail-content {
                                        font-size: 0.8em;
                                        padding: 0 10px;
                                        color: #FFF;
                                        text-align: center;
                                        margin-top: 10px;
                                        line-height:30px
                                    }
                                }
                            }
                        }
                    }

                    .position-container {
                        width: 60%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 60px;
                        .position-list {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid #CDB188;
                            width: 100%;

                            .position-item {
                                width: 95%;
                                border: 1px solid #CDB188;
                                height: 100px;
                                margin: 10px;
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                color: #A5A5A5;

                                .left {
                                    display: flex;
                                    flex-direction: column;
                                    span {
                                        line-height: 30px;
                                        
                                    }
                                }
                                .right {
                                    
                                }
                            }

                            .position-item.active {
                                background: #CDB188;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }
</style>